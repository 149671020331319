/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
// import LocalDiningIcon from '@material-ui/icons/LocalDining'
// import RestaurantIcon from '@material-ui/icons/Restaurant'
// import FastFoodIcon from '@material-ui/icons/Fastfood'
import {
  //   ListItemText,
  Typography,
  Card,
} from '@material-ui/core'
// import IconTile from '../IconTile'

import { newarkDiningStyle } from './newarkDiningStyles'
import { logDashboardEvent } from '../../../events'
import IconPicker from '../../../../apps/AppComponents/IconPicker/IconPicker'
import { MyDiningCard } from '../NewBrunswickDining/NewBrunswickDining'

export default function NewarkDining({ widgetData, widgetId, showNotes }) {
  const dispatch = useDispatch()
  const classes = newarkDiningStyle()
  const location = 'NW'
  const handleEventClick = (title, link) => {
    window.open(link, '_blank')

    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, link)
    }
  }
  return (
    <div className={showNotes === true ? classes.rootNotes : classes.root}>
      <Box className={classes.menuButtonsSection}>
        {widgetData[location].iconLinks?.map((iconLink) => (
          <ButtonBase
            key={iconLink.link}
            onClick={() => handleEventClick(iconLink.iconLabel, iconLink.link)}
            aria-label={iconLink.linkDescription}
            className={classes.menuButtonBase}
          >
            <IconPicker iconName={iconLink.icon} color="gray" size="2x" />
            <Typography variant="subtitle2">{iconLink.iconLabel}</Typography>
          </ButtonBase>
        ))}
      </Box>
      {widgetData[location].cardLinks?.length > 0 ? (
        widgetData[location].cardLinks?.map((data) => (
          <MyDiningCard
            key={data.link}
            classes={classes}
            handleEventClick={handleEventClick}
            data={data}
          />
        ))
      ) : (
        <Typography component="span" className={classes.heading}>
          No Dining Data To Display.
        </Typography>
      )}
      {widgetData[location].offCampusLinkMessage && (
        <Card className={classes.offlineContainer} variant="outlined">
          {widgetData[location].offCampusLinkMessage.text}
          <a
            href={widgetData[location].offCampusLinkMessage.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {` ${widgetData[location].offCampusLinkMessage.linkText}`}
          </a>
        </Card>
      )}
    </div>
  )
}
