import { makeStyles } from '@material-ui/core/styles'

export const useBottomSheetRoutesListStyles = makeStyles((theme) => ({
  routesContainer: {
    width: '100%',
    marginTop: 10,
    overflowX: 'auto',
    overflowY: 'auto',
    backgroundColor: theme.palette.backgroundColor.main,
  },
  circularProgressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10vh',
    width: '100vw',
  },
}))
