import { makeStyles } from '@material-ui/core/styles'

export const bottomSheetVehicleInfoStyles = makeStyles((theme) => ({
  headerTop: {
    fontSize: 26,
    margin: '0px 6px 10px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    [theme.breakpoints.down(580)]: {
      fontSize: 24,
    },
  },
  headerBottom: {
    fontSize: 26,
    margin: '0px 5px 14px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(580)]: {
      fontSize: 24,
    },
  },
  icon: {
    fontSize: 30,
    [theme.breakpoints.down(580)]: {
      fontSize: 26,
    },
  },
  busIcon: {
    fontSize: 28,
    marginBottom: 3,
    color: theme.palette.text.primary,
    [theme.breakpoints.down(580)]: {
      fontSize: 18,
      marginBottom: 7,
    },
  },

  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  flexColCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down(580)]: {
      marginTop: 15,
    },
  },

  estimatedArrivalText: {
    fontSize: 30,
    color: theme.palette.text.primary,
    maxWidth: '70vw',
    fontWeight: 'bold',
    [theme.breakpoints.down(580)]: {
      fontSize: 18,
    },
  },

  occupancyText: {
    fontSize: 25,
    maxWidth: '70vw',
    fontWeight: 'normal',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    display: 'inline-block',
    color: theme.palette.text.primary,
    verticalAlign: 'middle',
    [theme.breakpoints.down(580)]: {
      fontSize: 18,
    },
  },

  valueText: {
    fontSize: 24,
    color: theme.palette.text.primary,
    maxWidth: '70vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'middle',
    [theme.breakpoints.down(580)]: {
      fontSize: 20,
    },
  },

  centerContent: {
    width: '100%',
    color: 'black',
    backgroundColor: theme.palette.backgroundColor.ternary,
    borderRadius: '2px',
    padding: '2px 6px',
    margin: '0px 6px 8px 6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 155,
  },
}))
