import React from 'react'
import _ from 'lodash'
import { Typography, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { bottomSheetFirebaseMarkerStyles } from './BottomSheetFirebaseMarkerStyles'

function BottomSheetFirebaseMarker({ setSheetConfig }) {
  const classes = bottomSheetFirebaseMarkerStyles()

  const { selectedFirebaseMarker } = useSelector((s) => s.maps)

  React.useEffect(() => {
    if (!_.isEmpty(selectedFirebaseMarker)) {
      const sheetConfig = {
        title: selectedFirebaseMarker.title,
        sheetColor: selectedFirebaseMarker.color,
        displayBackButton: false,
        displayCloseButton: true,
      }
      setSheetConfig(sheetConfig)
    }
  }, [selectedFirebaseMarker])

  return (
    <Box display="flex" className={classes.firebaseMarkerBottomSheetContainer}>
      <div className={classes.descriptionTextContainer}>
        <Typography variant="h2" className={classes.descriptionText}>
          <RenderContent
            description={`${selectedFirebaseMarker.description}`}
            classes={classes}
          />
        </Typography>
      </div>
    </Box>
  )
}

const RenderContent = ({ description, classes }) => (
  <div className={classes.centerContent}>
    <Typography variant="h4" className={classes.firstStopNameLabel}>
      {description}
    </Typography>
  </div>
)

export default BottomSheetFirebaseMarker
