import { makeStyles } from '@material-ui/core/styles'

export const crStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: '480px',
    width: '335px',
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  image: {
    width: '100%',
    padding: '0 !important',
    margin: 0,
  },
  gridContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  eventsHolder: {
    flex: 1,
    width: '100%',
    margin: 0,
    padding: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  accordionHeaderContainer: {
    background: theme.palette.backgroundColor.ternary,
    borderRadius: '8px',
  },
  eventCard: {
    width: '95%',
    borderRadius: '1rem',
    margin: '0 auto 10px auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: -3,
    background: theme.palette.backgroundColor.secondary,
    borderTop: `1px solid ${theme.palette.backgroundColor.main}`,
  },
  footerIcon: {
    color: theme.palette.components.icon,
    cursor: 'pointer',
    padding: '2px',
    '&:hover': {
      backgroundColor: theme.palette.button.hover,
      borderRadius: '5px',
    },
  },
  iconfooter: {
    padding: '0px 5px',
    height: '28px',
    marginTop: '5px',
  },

  // about us tab
  aboutUsTextContainer: {
    padding: '8px 16px',
  },
  aboutUsHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '5px',
    height: '30px',
    color: theme.palette.primary.main,
  },
  aboutUsNavText: { paddingLeft: '5px' },
  aboutUsHeaderBackArrow: {
    cursor: 'pointer',
  },
  // contact card
  contactCardsContainer: {
    flex: 1,
    overflowY: 'auto',
    marginTop: '10px',
    padding: '5px 10px 5px 10px',
    width: 335,
  },
  contactCard: {
    width: '100%',
    '&:last-child': {
      marginTop: '20px',
    },
  },
  contactCardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '5px ',
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  contactIconTextContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.components.icon,
    cursor: 'pointer',
  },
  contactText: {
    '&::before': {
      content: '" "',
      whiteSpace: 'pre',
    },
    color: theme.palette.components.linkText,
  },
  // events tab

  eventsHeaderContainer: {
    textAlign: 'center',
    padding: '8px',
  },
  eventsCalendarButtonContainer: {
    marginTop: '8px',
    width: '50%',
  },

  // checklist tab
  accordionContainer: {
    width: '100%',
    height: '350px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  accordion: {
    width: '100%',
  },
  accordionItem: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.base.lightGray}`,
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.base.lightGray}`,
    },
  },
  accordionItemText: {
    paddingTop: '3px',
  },
  accordionDetails: {
    background: theme.palette.backgroundColor.ternary,
    borderRadius: '8px',
  },
  itemBullet: {
    color: '#006FA8',
    display: 'flex',
  },
  downArrow: {
    color: theme.palette.components.rcsaBlue,
  },

  // amenities tab
  amenitiesListContainer: {
    padding: '5px',
    height: '350px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  amenitiesItemContainer: {
    padding: '0px',
  },
  amenitiesTextContainer: {
    padding: '0px 5px',
  },
}))

export default crStyles
