import React from 'react'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import WebOutlinedIcon from '@material-ui/icons/WebOutlined'
import IconPicker from '../../../../apps/AppComponents/IconPicker/IconPicker'
import crStyles from '../crStyles'
import { uiText } from '../data/cmrParams'
const CRcontact = ({ handleClick }) => {
  const classes = crStyles()
  return (
    <Box className={classes.contactCardsContainer}>
      <Card variant="outlined" className={classes.contactCard}>
        <CardContent className={classes.contactCardContent}>
          <Typography variant="h3">
            <b>Contact Us</b>
          </Typography>
          <Typography variant="h5">
            <b>Phone number</b>
          </Typography>
          <Box className={classes.contactIconTextContainer}>
            <IconPicker iconName="fa-mobile-screen-button" size="1x" />
            <Typography
              variant="body"
              component="a"
              href={`tel:${uiText.CONTACT_CARD_PHONENUMBER}`}
              className={classes.contactText}
            >
              {uiText.CONTACT_CARD_PHONENUMBER}
            </Typography>
          </Box>
          <Typography variant="h6">
            <b>Email</b>
          </Typography>
          <Box className={classes.contactIconTextContainer}>
            <IconPicker iconName="fa-regular fa-envelope" size="1x" />
            <Typography
              variant="body"
              component="a"
              href="mailto:information.rcsa@gmail.com"
              className={classes.contactText}
            >
              {uiText.CONTACT_CARD_INFOEMAIL}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Card variant="outlined" className={classes.contactCard}>
        <CardContent className={classes.contactCardContent}>
          <Typography variant="h3">
            <b>Questions</b>
          </Typography>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            {uiText.CONTACT_QUESTION_CARD_SUBHEADER}
          </Typography>
          <Typography variant="h6">
            <b>Website</b>
          </Typography>
          <Box
            className={classes.contactIconTextContainer}
            onClick={() => handleClick(uiText.CONTACT_CARD_WEBURL)}
          >
            <WebOutlinedIcon />
            <Typography
              variant="body"
              component="a"
              className={classes.contactText}
            >
              https://sca.rutgers.edu/services/
              <br />
              &nbsp;commuter-life
            </Typography>
          </Box>
          <Typography variant="h6">
            <b>Email</b>
          </Typography>
          <Box className={classes.contactIconTextContainer}>
            <IconPicker iconName="fa-regular fa-envelope" size="1x" />
            <Typography
              variant="body"
              component="a"
              href="mailto:commuters@echo.rutgers.edu"
              className={classes.contactText}
            >
              {uiText.CONTACT_CARD_QUESTIONS_EMAIL}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default CRcontact
