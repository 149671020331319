import React from 'react'
import { Card, Typography, CardContent, Grid } from '@material-ui/core'
//  Add CardMedia when pulling images from firebase as:
/* 
 const [image, setImage] = React.useState(
    infoObj?.IMAGE,
  )

  React.useEffect(() => {
    getImageFromFirebaseWithHook(img, setImage)
  }, []) */

const CRamenityCard = ({ data, classes, location }) => {
  const infoObj = data[location]
  return (
    <Card style={{ display: 'flex' }} variant="outlined">
      <CardContent className={classes.amenitiesItemContainer}>
        <Grid container>
          <Grid
            item
            xs={6}
            alignContent="center"
            direction="column"
            style={{ textAlign: 'center' }}
          >
            <Typography variant="h5">
              <b>{infoObj?.TITLE}</b>
            </Typography>
            <Typography variant="h6">
              <b>{infoObj?.SUBTITLE}</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <img
              src={infoObj?.IMAGE}
              alt={infoObj?.IMAGE_ALTEXT}
              width="100%"
            />
          </Grid>
          <Grid item xs={12} className={classes.amenitiesTextContainer}>
            <Typography variant="body1">
              {infoObj?.AMENITIES_LIST_HEADER_TEXT}
            </Typography>
            <Typography variant="body1">
              {infoObj?.AMENITIES_LIST.map((item) => (
                <Typography key={item} variant="body1">
                  &bull;{item}
                </Typography>
              ))}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CRamenityCard
