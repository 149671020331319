import { makeStyles } from '@material-ui/core/styles'

export const bottomSheetFirebaseMarkerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    // height: 175,
    width: '100%',
    bottom: 0,
    borderStartEndRadius: 24,
    borderTopLeftRadius: 24,
    background: theme.palette.background.paper,
    [theme.breakpoints.down(640)]: {
      width: '100%',
      height: 190,
    },
  },
  routeMarker: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'none',
    height: 'auto',
    width: 170,
    borderRadius: '1rem',
    border: 'none',
    marginLeft: 15,
    marginRight: 15,
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down(550)]: {
      width: 130,
      height: 130,
    },
    [theme.breakpoints.down(370)]: {
      width: 90,
      height: 90,
    },
  },
  routeMarkerInactive: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'none',
    height: 'auto',
    width: 170,
    borderRadius: '1rem',
    border: 'none',
    marginLeft: 15,
    marginRight: 15,
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down(550)]: {
      width: 130,
      height: 130,
    },
    [theme.breakpoints.down(370)]: {
      width: 90,
      height: 90,
    },
    opacity: 0.7,
  },
  estimatedArrivalText: {
    fontSize: 20,
    color: '#FFFFFF',
  },
  firstStopNameLabel: {
    color: theme.palette.text.ternary,
    textAlign: 'center',
    width: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.down(580)]: {
      fontSize: '1rem',
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '0.7rem',
    },
  },
  stopNameLabel: {
    color: '#FFFFFF',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down(580)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '0.7rem',
    },
  },
  firebaseMarkerBottomSheetContainer: {
    width: '100%',
    marginTop: 10,
    height: 100,
    fontWeight: 'bold',
    backgroundColor: theme.palette.backgroundColor.ternary,
    borderRadius: '2px',
    padding: '2px 6px',
    margin: '0px 6px 8px 6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down(640)]: {
      overflow: 'hidden',
    },
  },
  stopNameLabelNone: {
    color: '#FFFFFF',
    textAlign: 'center',
    width: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.down(580)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '0.7rem',
    },
  },
  backButton: {
    position: 'absolute',
    left: 5,
    top: -10,
  },
  appBarIcon: {
    fontSize: 24,
  },
  descriptionContainer: {
    marginLeft: 16,
    marginTop: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  descriptionTextContainer: {
    marginLeft: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  descriptionText: {
    fontWeight: 400,
    fontSize: '1.5rem',
    letterSpacing: '-0.025em',
    color: theme.palette.backgroundColor.primary,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
}))
