import * as React from 'react'
import { Box, Typography, Grid, Divider } from '@material-ui/core'
import WidgetFooterButton from '../WidgetFooterButton'
import { uiText, crURLs } from '../data/cmrParams'

import crStyles from '../crStyles'
const CRevents = ({ handleClick }) => {
  const classes = crStyles()
  return (
    <Box>
      <Box className={classes.eventsHeaderContainer}>
        <Typography variant="h5">
          <b>{uiText.EVENTS_HEADER_TEXT}</b>
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.aboutUsTextContainer}
      >
        <Grid item style={{ textAlign: 'center' }}>
          <img
            src="./assets/images/cr-event-header.png"
            alt="Commuter Resources event "
            width="95%"
          />
        </Grid>
        <Grid item>
          <Typography variant="body1">{uiText.EVENTS_BODY_TEXT}</Typography>
        </Grid>
        <Grid item className={classes.eventsCalendarButtonContainer}>
          <WidgetFooterButton
            title="Events Calendar"
            bgColor="#006FA8"
            handleClick={() => handleClick(crURLs.RCSA_EVENTSPAGE)}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export default CRevents
