import * as React from 'react'
import {
  Box,
  Typography,
  Grid,
  Divider,
  // Card,
  // CardMedia,
  // CardContent,
} from '@material-ui/core'
import CRamenityCard from './CRAmenityCard'
import { uiText, amenitiesData } from '../data/cmrParams'

import crStyles from '../crStyles'
const CRamenities = () => {
  const classes = crStyles()
  return (
    <Box>
      <Box className={classes.eventsHeaderContainer}>
        <Typography variant="h5">
          <b>{uiText.AMENITIES_HEADER_TEXT}</b>
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.amenitiesListContainer}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {amenitiesData.lounges.map((amData) => (
            <Grid key={amData.TITLE} item>
              <CRamenityCard
                data={amData}
                classes={classes}
                location={Object.keys(amData)[0]}
              />
            </Grid>
          ))}

          {/* <Grid item>
            <Card style={{ display: 'flex' }} variant="outlined">
              <CardContent>
                <Typography variant="body1">
                  <b>
                    Douglass Student Center Commuter Lounge (In the NJC Lounge)
                  </b>
                </Typography>
                <Typography variant="body1">
                  {uiText.AMENITIES_LIST_HEADER_TEXT}
                </Typography>
                <Box>
                  {uiText.AMENITIES_DOUGLASS_LIST.map((item) => (
                    <Typography key={item} variant="body1">
                      {' '}
                      &bull;{item}
                    </Typography>
                  ))}
                </Box>
              </CardContent>
              <CardMedia>
                <img
                  src="./assets/images/douglas_lounge.jpg"
                  alt="Commuter Resources event "
                  height={190}
                  width={125}
                />
              </CardMedia>
            </Card>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  )
}
export default CRamenities
