export const uiText = Object.freeze({
  ABOUT_US_1: 'We are the ',
  ABOUT_US_2:
    ' and we are here to advocate and plan programs for the commuter students at Rutgers. Stop by our lounges or contact us to chat, ask questions, voice concerns and meet other students. We have various amenities available for you to use in both of our lounges, check the detailed description below to access either of our commuter lounges!',
  ABOUT_US_NAVTEXT: 'About Us',
  EVENTS_HEADER_TEXT: 'Find Events for Commuters!',
  EVENTS_BODY_TEXT:
    'RCSA always has events going on. Check out our events calendar for more information.',
  AMENITIES_HEADER_TEXT: 'Commuter Lounges',
  AMENITIES_LIST_HEADER_TEXT: ' In the lounge you wil find:',
  AMENITIES_DOUGLASS_LIST: [
    ' Tables',
    ' A television',
    ' A commuter kitchen',
    ' Plenty of comfortable seating',
  ],
  AMENITIES_BUSCH_LIST: [
    ' A microwave',
    ' A refrigirator to store your food',
    ' Computers with Internet access ',
    ' A television',
    ' Videogame equipment',
    ' Plenty of comfortable seating',
  ],
  CONTACT_QUESTION_CARD_SUBHEADER:
    'Check out the Student Centers and Activities Department',
  CONTACT_CARD_PHONENUMBER: '848-445-2055',
  CONTACT_CARD_INFOEMAIL: 'information.rcsa@gmail.com',
  CONTACT_CARD_WEBURL: 'https://sca.rutgers.edu/services/commuter-life',
  CONTACT_CARD_QUESTIONS_EMAIL: 'commuters@echo.rutgers.edu',
})

export const crURLs = Object.freeze({
  INSTAGRAM: 'https://www.instagram.com/RUCommuter',
  DISCORD: 'https://discord.com/invite/FFW5EKq',
  GETINVOLVED: 'https://rutgers.campuslabs.com/engage/organization/rucommuter',
  RCSA_HOMEPAGE: 'http://rcsa.rutgers.edu/',
  RCSA_EVENTSPAGE: 'http://rcsa.rutgers.edu/upcoming-events/',
})

export const amenitiesData = Object.freeze({
  lounges: [
    {
      BUSCH: {
        AMENITIES_LIST_HEADER_TEXT: uiText.AMENITIES_LIST_HEADER_TEXT,
        TITLE: 'Busch Student Center',
        SUBTITLE: 'Room 171',
        IMAGE: './assets/images/busch_student_center.jpg',
        IMAGE_ALTEXT:
          'A lounge with several cushion chairs, a couch, multiple tables, a television with video games.',

        AMENITIES_LIST: uiText.AMENITIES_BUSCH_LIST,
      },
    },

    {
      DOUGLASS: {
        AMENITIES_LIST_HEADER_TEXT: uiText.AMENITIES_LIST_HEADER_TEXT,
        TITLE: 'Douglass Student Center Commuter Lounge ',
        SUBTITLE: '(In the NJC Lounge)',
        IMAGE: './assets/images/douglas_lounge.jpg',
        IMAGE_ALTEXT:
          'A lounge with several cushion chairs, a couch, multiple tables, a television with video games.',

        AMENITIES_LIST: uiText.AMENITIES_DOUGLASS_LIST,
      },
    },
  ],
})
