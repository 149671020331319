import * as React from 'react'
import { Box, Typography, Grid, Divider } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack'
import { uiText } from '../data/cmrParams'

import crStyles from '../crStyles'
const CRaboutUs = ({ handleClick }) => {
  const classes = crStyles()
  return (
    <Box>
      <Box className={classes.aboutUsHeaderContainer}>
        <ArrowBackIosIcon
          onClick={handleClick}
          className={classes.aboutUsHeaderBackArrow}
        />
        <Typography variant="h4" className={classes.aboutUsNavText}>
          {uiText.ABOUT_US_NAVTEXT}
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.aboutUsTextContainer}
      >
        <Grid item style={{ backgroundColor: '#006FA8' }}>
          <img
            src="./assets/images/rc-header-logo.png"
            alt="icon"
            width="100%"
          />
        </Grid>
        <Grid item>
          <Typography variant="body1" style={{ lineHeight: '18px' }}>
            {uiText.ABOUT_US_1}
            <b>R</b>utgers&nbsp;<b>C</b>ommuter <b>S</b>tudent <b>A</b>
            ssociation {uiText.ABOUT_US_2}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
export default CRaboutUs
