/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

/*
    This file contains the redux tool kit code for the state that the DidYouKnow component uses, the call that is made to obtain data and selector functions that we can call inside of the DidYouKnow component to get access to our state.
*/

// UPDATE: env API when backend is ready

export const fetchCommuterResources = createAsyncThunk(
  'fetchCommuterResources',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const res = await axios.get(process.env.REACT_APP_DYK_ENDPOINT, {
      headers: {
        JWT: jwtToken,
      },
    })
    return res.data
  },
)

// This is the initial state object the reducers and extraReducers will change based on whatever we need to change it to. It is required when creating the createSlice object.
const initialState = {
  status: 'idle',
  data: {
    title: 'Commuter Resources',
    image: '',
    content: '',
    categories: [],
  },
}

const commuterResourceSlice = createSlice({
  name: 'commuterResourceWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCommuterResources.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchCommuterResources.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchCommuterResources.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.title = action.payload.title
      state.data.image = action.payload.image
      state.data.content = action.payload.content
      state.data.categories = action.payload.categories
    },
  },
})
// List of selector functions that can be used to access state inside of the CommuterResources.js Component.
export const selectCRData = (state) => state.commuterResource.data
export const selectCRStatus = (state) => state.commuterResource.status

export default commuterResourceSlice.reducer
