import { makeStyles } from '@material-ui/core/styles'

export const newarkDiningStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 440,
    overflowY: 'auto',
  },
  rootNotes: {
    width: '100%',
    height: 394,
    overflowY: 'auto',
  },
  diningCard: {
    width: '95%',
    borderRadius: '10px',
    margin: '0 auto 10px auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  icon: {
    fontSize: 35,
    color: theme.palette.components.icon,
  },
  offlineContainer: {
    background: theme.palette.backgroundColor.ternary,
    margin: 5,
    padding: 5,
    color: theme.palette.primary.main,
  },
  customIconContainer: {
    marginLeft: 10,
  },
  img: {
    width: '100%',
    height: 90,
  },
  menuButtonsSection: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: 10,
    paddingBottom: 10,
  },
  menuButtonBase: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px',
    width: '100px',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
      backgroundColor: theme.palette.button.hover,
      borderRadius: '4px',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.hover,
      borderRadius: '4px',
    },
  },
}))
