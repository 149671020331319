import * as React from 'react'
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import { uiText } from '../data/cmrParams'

import crStyles from '../crStyles'

const DropDownList = ({ classes }) => {
  const listItems = [
    {
      title: 'In Your Bag',
      content: [
        'Rutgers ID',
        'Drivers License',
        'Refillable Water Bottle',
        'Wallet',
        'Food/Money',
        'Toiletries (Lotion, Chapstick, etc.)',
        'Spare Keys',
      ],
    },
    {
      title: 'In Your Car',
      content: ['Umbrella', 'Extra Cothes', 'RU Map', 'Nonperishable Snacks'],
    },
    {
      title: 'Essential College Tech',
      content: [
        'Laptop and Charger',
        'Cell Phone and Charger',
        'The Rutgers App',
        'Mobile Device Car Charger',
        'Headphones',
      ],
    },
    {
      title: 'For Inclement Weather',
      content: [
        'Wiper Fluid',
        'Ice Scraper with Brush',
        'Blankets',
        'Gloves, Scarf, Hat, etc.',
        'Shovel',
      ],
    },
    {
      title: 'For Roadside Safety',
      content: [
        'Flashlight and Batteries',
        'Jumper Cables',
        'Spare Tire or Donut',
        'Wheel Lock Key',
        'First Aid Kit',
        'Whistle',
      ],
    },
    {
      title: 'Important Numbers',
      content: [
        'For Emergencies, Dial 9-1-1',
        'RUPD: (732)932-7211 (Non-emergencies, lockouts, jump starts, etc.)',
        'Knight Mover: (732) 932-7433 (Individualized Bus Service from M-Th: 3:00am – 6:00am.)',
      ],
    },
  ]

  return (
    <Grid container className={classes.accordionContainer}>
      {listItems.map((item) => (
        <Grid
          item
          key={item.title}
          style={{ marginBottom: '5px', width: '320px' }}
        >
          <Accordion
            className={classes.accordion}
            // variant="outlined"
          >
            <AccordionSummary
              className={classes.accordionHeaderContainer}
              expandIcon={<ExpandMoreIcon className={classes.downArrow} />}
            >
              <Typography variant="subtitle1">{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {item.content.length === 0 ? (
                <Typography variant="body1">no items here!</Typography>
              ) : (
                <Grid container direction="column" spacing={0}>
                  {item.content.map((listItem) => (
                    <Grid item key={listItem} className={classes.accordionItem}>
                      <Typography variant="h2" className={classes.itemBullet}>
                        &bull;
                      </Typography>
                      <Typography
                        className={classes.accordionItemText}
                        variant="body1"
                      >
                        &nbsp; {listItem}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  )
}

const CRcheckList = () => {
  const classes = crStyles()
  return (
    <Box>
      <Box className={classes.contactCardsContainer}>
        <DropDownList classes={classes} />
      </Box>
    </Box>
  )
}
export default CRcheckList
