import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Box } from '@material-ui/core'

import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import { crStyles } from './crStyles'
import Widget from '../Widget'
import {
  a11yProps,
  TabPanel,
} from '../../DashboardComponents/TabPanel/TabPanel'
import {
  RutgersTabs,
  RutgersTab,
} from '../../DashboardComponents/Tabs/RutgersTabs'

import { logDashboardEvent } from '../../events'
// import { selectCRData, selectCRStatus } from './CommuterResourceSlice'
import WidgetFooterButton from './WidgetFooterButton'
import CRamenities from './components/CRamenities'
import CRaboutUs from './components/CRaboutUs'
import CRcontact from './components/CRcontact'
import CRevents from './components/CRevents'
import CRcheckList from './components/CRcheckList'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { crURLs } from './data/cmrParams'
const CommuterResources = ({
  title,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
  widgetId,
}) => {
  // use above when API is ready
  // const widgetData = useSelector(selectCRData)
  // const widgetCallState = useSelector(selectCRStatus)
  const widgetData = true
  const widgetCallState = 'fulfilled'
  // const dispatch = useDispatch()

  React.useEffect(() => {
    // const user = localStorage.getItem('user') ?? 'visitor'
    // const isGuest = user === '"visitor"' || user === ''
    // not sure if we need isGuest for this might come into picture when voting is included
    // dispatch(fetchPolicyItems(isGuest))
  }, [])

  return (
    <Widget>
      <Widget.Header title={title} />
      <Widget.Content
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderCRstate(widgetCallState, widgetData, widgetId, title)}
      </Widget.Content>
    </Widget>
  )
}

function renderCRstate(widgetCallState, widgetData, widgetId, title) {
  // when backend is ready switch should use widgetCallState
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded widgetData={widgetData} widgetId={widgetId} title={title} />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded({ widgetId, title }) {
  // when API is ready add back widgetData prop
  const darkMode = useSelector((s) => s?.settings?.data?.darkMode)
  const classes = crStyles()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = React.useState(0)
  const [showMainView, setShowMainView] = React.useState(1)
  const resources = ['Amenities', 'Checklist', 'Events', 'Contact']

  const handleTabChange = (e, value) => {
    setCurrentTab(value)
  }

  function switchView(view) {
    setShowMainView(Boolean(view))
  }
  const handleClick = (url) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardLinkEvent', widgetId, title)
      window.open(url)
    }
  }
  return (
    <>
      {showMainView ? (
        <>
          <Box className={classes.modalTabs}>
            <RutgersTabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="commuter resources Tab"
              className={classes.tabBackground}
            >
              {resources.map((tab, index) => (
                <RutgersTab key={tab} label={tab} {...a11yProps(index)} />
              ))}
            </RutgersTabs>
          </Box>
          <Box>
            <TabPanel
              value={currentTab}
              index={0}
              className={classes.tabPanel0}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CRamenities />
              </Grid>
            </TabPanel>
            <TabPanel
              value={currentTab}
              index={1}
              className={classes.tabPanel1}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CRcheckList />
              </Grid>
            </TabPanel>
            <TabPanel
              value={currentTab}
              index={2}
              className={classes.tabPanel1}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CRevents handleClick={handleClick} />
              </Grid>
            </TabPanel>
            <TabPanel
              value={currentTab}
              index={3}
              className={classes.tabPanel1}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CRcontact handleClick={handleClick} />
              </Grid>
            </TabPanel>
          </Box>
        </>
      ) : (
        <CRaboutUs handleClick={switchView} />
      )}

      <Box className={classes.footer}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          className={classes.iconfooter}
        >
          {/* Add special case for these icons in image to pass rpoprs to iconpicker */}
          <Grid
            item
            className={classes.footerIcon}
            onClick={() => handleClick(crURLs.INSTAGRAM)}
          >
            <IconPicker iconName="fa fa-instagram" size="2x" />
          </Grid>
          <Grid
            item
            className={classes.footerIcon}
            onClick={() => handleClick(crURLs.DISCORD)}
          >
            <IconPicker iconName="fa-brands fa-discord" size="2x" />
          </Grid>
          <Grid
            item
            className={classes.footerIcon}
            onClick={() => handleClick(crURLs.GETINVOLVED)}
          >
            <img
              src={
                darkMode
                  ? './assets/images/svgs/get-involved-dark.svg'
                  : './assets/images/svgs/get-involved.svg'
              }
              alt="icon"
              height={20}
              width={100}
              style={{ paddingTop: '4px' }}
            />
          </Grid>
          <Grid
            item
            className={classes.footerIcon}
            onClick={() => setShowMainView(0)}
          >
            <img
              src="./assets/images/svgs/cr-logo2.svg"
              alt="icon"
              height={20}
              width={100}
            />
          </Grid>
        </Grid>

        <WidgetFooterButton
          handleClick={() => handleClick(crURLs.RCSA_HOMEPAGE)}
          title="Visit Commuter Resources"
          bgColor="#006FA8"
        />
      </Box>
    </>
  )
}

export default CommuterResources
